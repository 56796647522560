@import 'styles/variables';

.DragElement {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  margin-right: $unit / 4;
}
