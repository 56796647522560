@mixin pseudo($pos: absolute, $display: block, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin sm {
  @media (max-width: $breakpoint_sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: $breakpoint_md) {
    @content;
  }
}
