@import 'styles/variables';

.CheckboxIcon {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-color: white;
  border: solid 1px $color_border;
  transition: border-color $duration_shorter $easing_out;
}

.CheckboxIcon-Svg {
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0;
  transform: scale(0.7);
  transition: $duration_shorter $easing_out;
  transition-property: opacity, transform;
  .CheckboxIcon_checked > & {
    opacity: 1;
    transform: scale(1);
  }
  .CheckboxIcon_checked.CheckboxIcon_disabled > & {
    opacity: 0.5;
  }
}
