@import 'styles/variables';
@import 'styles/mixins';

.AppContent {
  padding: $unit / 2;
  padding-left: $sidebar-width;
  @include md {
    padding-left: $unit / 2;
  }
}

.AppContent-Main {
  border-radius: 12px;
  background-color: white;
  padding: $unit * 2 $unit * 4 $unit * 4;
  min-height: calc(100vh - 24px);
  overflow: hidden;
  @include sm {
    padding-left: $unit * 2.5;
    padding-right: $unit * 2.5;
  }
  @include md {
    padding-top: $unit * 3.5;
  }
}
