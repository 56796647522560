@import 'styles/variables';

.SurveyForm-Sections {
  margin-top: $unit * 1.5;
  margin-bottom: $unit * 1.5;
}

.SurveyForm-List {
  margin-bottom: $unit / 2;
}

.SurveyForm-Pages {
  margin-top: $unit / 2;
}
