// zeplin
$color_white: #f9f9f9;
$color_grey: #7d7d7d;
$color_black: #313131;
$color_black-two: #141414;
$color_light-tan: #ffeaa6;
$color_yellow-tan: #ffdf74;
$color_camel: #db9b46;
$color_brownish-orange: #c78021;
$color_dark-sky-blue: #3bace0;

// custom
$color_selected: rgba(black, 0.08);
$color_border: rgba($color_black, 0.26);
$color_filtered: rgba($color_camel, 0.05);
$color_success: #8aca80;
$color_error: #ef5350;

$unit: 24px;
$sidebar-width: 236px;
$table-footer-height: 80px;

$duration_shortest: 150ms;
$duration_shorter: 200ms;
$duration_short: 250ms;
$duration_standard: 300ms;
$duration_medium: 500ms;

$easing_in-out: cubic-bezier(0.4, 0, 0.2, 1);
$easing_out: cubic-bezier(0, 0, 0.2, 1);

$breakpoint_sm: 1079px;
$breakpoint_md: 1279px;
