@import 'styles/variables';

.Company-Panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $unit;
}

.Company-Title {
  display: flex;
  justify-content: space-between;
}
