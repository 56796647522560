body {
  overflow-y: scroll;
  min-width: 960px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  white-space: pre-line;
}

ul,
nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  cursor: pointer;
  outline-color: $color_camel;
  font: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  text-align: left;
  padding: 0;
  &:disabled {
    cursor: default;
  }
}

a {
  cursor: pointer;
  outline-color: $color_camel;
  user-select: none;
  text-decoration: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-tap-highlight-color: transparent;
}

img {
  height: auto;
  display: block;
  pointer-events: none;
  user-select: none;
}

::selection {
  background: $color_light-tan;
}

input {
  outline-color: $color_camel;
}

::placeholder {
  color: rgba($color_grey, 0.6);
}
:-ms-input-placeholder {
  color: rgba($color_grey, 0.6);
}
::-ms-input-placeholder {
  color: rgba($color_grey, 0.6);
}
