$fonts: ('thin' 100, 'light' 300, 'regular' normal, 'medium' 500, 'bold' bold);

@each $type, $value in $fonts {
  @font-face {
    font-family: 'Carnas';
    font-weight: $value;
    font-style: normal;
    font-display: swap;
    src: url('../assets/fonts/carnas_#{$type}.woff2') format('woff2'),
      url('../assets/fonts/carnas_#{$type}.woff') format('woff');
  }
}
