@import 'styles/variables';

.Loader {
  padding: $unit * 3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &_type_block,
  &_type_table {
    position: absolute;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(white, 0.5);
    z-index: 1;
  }
  &_type_block {
    height: 100%;
  }
  &_type_table {
    height: calc(100% - #{$table-footer-height});
  }
}
