@import 'styles/variables';
@import 'styles/mixins';

.QuestionDialog {
  min-width: 600px;
}

.QuestionDialog-Panel {
  display: flex;
  align-items: center;
  margin-bottom: $unit * 1.5;
}

.QuestionDialog-Title {
  flex-shrink: 0;
  margin-right: $unit * 1.5;
}

.QuestionDialog-Search {
  width: 100%;
}

.QuestionDialog-Part {
  font-weight: 300;
  font-size: 18px;
  &:not(:last-child) {
    margin-bottom: $unit * 1.5;
  }
}

.QuestionDialog-PartTitle {
  color: $color_grey;
  font-weight: 300;
  font-size: 24px;
  margin-bottom: $unit / 2;
}

.QuestionDialog-Section {
  &:not(:last-child) {
    margin-bottom: $unit;
  }
}

.QuestionDialog-SectionTitle {
  color: $color_grey;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: $unit / 4;
}

.QuestionDialog-Item {
  border-radius: 6px;
  &::before {
    @include pseudo(relative);
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    background: url('../../assets/icons/question-filled.svg') center / contain
      no-repeat;
    color: $color_grey;
    margin-right: $unit / 2;
  }
  &_selected {
    &.MuiListItem-root {
      opacity: 1;
    }
    color: rgba(black, 0.5);
    &::before {
      opacity: 0.5;
    }
  }
  &_hiddenBy {
    padding-left: $unit * 2;
  }
}

.QuestionDialog-Value {
  color: $color_brownish-orange;
  margin-right: $unit / 2;
  .QuestionDialog-Item_selected > & {
    opacity: 0.5;
  }
}

.QuestionDialog-Selected {
  color: $color_brownish-orange;
  margin-left: $unit / 2;
  white-space: nowrap;
}

.QuestionDialog-Plug {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color_grey;
  font-size: 18px;
}
