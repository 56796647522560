@import 'styles/variables';

.CourseContent-BlockItem {
  margin-right: $unit;
  &:nth-child(2) {
    width: 40%;
  }
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    width: 20%;
  }
}

.CourseContent-Plug {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
}
