@import 'styles/variables';
@import 'styles/mixins';

.SidebarButton {
  position: absolute;
  left: 24px;
  top: 24px;
}

.Sidebar-Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $sidebar-width;
  padding-top: $unit * 2;
  text-align: center;
  height: 100%;
}

.Sidebar-Logo {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $unit * 3.5;
}

.Sidebar-Links {
  margin-bottom: $unit;
}

.Sidebar-Link {
  font-weight: 500;
  padding: $unit / 2 $unit * 1.875;
  min-height: 48px;
  &_active {
    background-color: $color_selected;
  }
}

.Sidebar-Logout {
  width: 48px;
  height: 48px;
  align-self: center;
  margin-bottom: $unit;
}
