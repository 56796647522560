@import 'styles/variables';

.InstructionForm-List {
  margin-top: $unit / 1.33;
  margin-bottom: $unit / 2;
}

.InstructionForm-Number {
  margin-right: $unit / 2;
}
