@import 'styles/variables';

.Option-Block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Option-Wrapper {
  width: calc(100% - 144px - #{$unit});
}

.Option-Fields {
  width: 100%;
}

.Option-Input,
.Option-SubInput {
  width: 100%;
  padding: $unit / 12 $unit / 6;
  font: inherit;
  border: 1px solid transparent;
  border-radius: 6px;
  margin-bottom: $unit / 12;
  &:hover {
    border-color: $color_black-two;
  }
}

.Option-Input {
  font-size: 16px;
}

.Option-SubInput {
  color: $color_grey;
}

.Option-Actions {
  flex-shrink: 0;
}

.Option-Addition {
  padding-left: $unit * 2.25;
  padding-right: $unit * 2;
}

.Option-Question {
  margin-top: $unit / 2;
  &:not(:last-of-type) {
    margin-bottom: $unit / 2;
  }
}
