@import 'styles/variables';

.CompanyCourses {
  margin-bottom: $unit * 2;
}

.CompanyCourses-Wrapper {
  display: flex;
  align-items: center;
  &:first-child {
    width: 45%;
    margin-right: $unit;
  }
  &:nth-child(2) {
    margin-right: $unit;
    justify-content: flex-end;
    width: 22.5%;
  }
  &:last-child {
    justify-content: flex-end;
    width: 32.5%;
  }
}

.CompanyCourses-Text {
  font-size: 18px;
  font-weight: 300;
  margin-right: $unit / 4;
  &_nowrap {
    white-space: nowrap;
  }
}

.CompanyCourses-Plug {
  padding: $unit / 4 $unit / 3;
  font-weight: 500;
  visibility: hidden;
}
