@import 'styles/variables';

.Auth {
  text-align: center;
  max-width: 360px;
  margin: $unit * 3 auto;
}

.Auth-Logo {
  margin: auto;
  margin-bottom: $unit / 2;
}

.Auth-Title {
  margin-bottom: $unit;
}
