@import 'styles/variables';

.BlockButton {
  justify-content: flex-start;
  border: 0;
  width: 100%;
  padding-left: $unit;
  padding-right: $unit;
  min-height: 54px;
  font-size: 16px;
  color: $color_dark-sky-blue;
  border-radius: 12px;
  background-color: rgba($color_dark-sky-blue, 0.08);
}
