@import 'styles/variables';

.Notification {
  display: inline-flex;
  flex-grow: 0;
  font-size: 16px;
  max-width: 500px;
  min-width: 250px;
  padding-left: $unit;
  padding-right: $unit;
  white-space: pre-line;
  &_type_success {
    color: black;
    background-color: $color_yellow-tan;
  }
  &_type_error {
    color: white;
    background-color: $color_error;
  }
}
