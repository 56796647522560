@import 'styles/variables';

.CoursePart-Text {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: $unit;
  color: $color_grey;
  &:last-of-type {
    margin-bottom: $unit / 2;
  }
}
