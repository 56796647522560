@import 'styles/variables';

.Breadcrumbs {
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-bottom: $unit;
  &_noMargin {
    margin-bottom: 0;
  }
}

.Breadcrumbs-Back {
  margin-left: -$unit / 2;
}

.Breadcrumbs-Icon {
  transform: rotate(-180deg);
}

.Breadcrumbs-Link {
  font-weight: bold;
}

.Breadcrumbs-Current {
  display: inline-block;
  padding: $unit / 4 $unit / 3;
}
