@import 'styles/variables';

.ImageField {
  position: relative;
  display: block;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-top: $unit / 4;
  margin-bottom: $unit / 4;
  overflow: hidden;
}

.ImageField-Button {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid $color_border;
  color: $color_grey;
  font-size: 16px;
  flex-direction: column;
  overflow: hidden;
  &:hover:not(.ImageField-Button_disabled) {
    border-color: $color_black-two;
  }
}

.ImageField-Required {
  position: absolute;
  right: -9px;
}

.ImageField-Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ImageField-Input {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  width: 1px;
  height: 1px;
  z-index: -1;
}
