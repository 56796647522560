// complex

.Title {
  font-size: 24px;
  font-weight: 300;
  &_indent {
    margin-bottom: $unit * 1.5;
  }
  &_nowrap {
    white-space: nowrap;
  }
}

.TextLarge {
  font-size: 18px;
  font-weight: 300;
}

.Action {
  width: 48px;
  height: 48px;
  color: $color_dark-sky-blue;
  &:hover {
    background-color: rgba($color_dark-sky-blue, 0.08);
  }
}

.BlocksGroup {
  &:not(:last-child) {
    margin-bottom: $unit / 2;
  }
  &_topIndent {
    margin-top: $unit / 2;
  }
}

.Block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px $color_border;
  background-color: $color_white;
  padding: $unit / 1.5;
  min-height: 72px;
  border-radius: 12px;
  &:not(:last-child) {
    margin-bottom: $unit / 2;
  }
  &_simple {
    padding-left: $unit;
    padding-right: $unit;
    &.Block_option {
      display: flex;
      align-items: center;
    }
  }
  &_small,
  &_option {
    padding-left: $unit / 3;
    padding-right: $unit / 3;
    border-radius: 8px;
    &.Block_simple {
      padding-left: $unit / 1.5;
      padding-right: $unit / 1.5;
    }
  }
  &_small {
    min-height: 54px;
    padding-top: $unit / 12;
    padding-bottom: $unit / 12;
  }
  &_option {
    display: block;
    min-height: 66px;
    padding-top: $unit / 3;
    padding-bottom: $unit / 3;
  }
  &_empty {
    background-color: white;
  }
  .BlocksGroup > & {
    margin-bottom: 0;
    border-top: 0;
    border-radius: 0;
    &:first-child {
      border-top: solid 1px $color_border;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}

.LinkIcon {
  margin-right: $unit / 4;
  &_invert {
    transform: rotate(180deg);
  }
}

.Label {
  display: inline-block;
  text-align: center;
  width: 100px;
  border-radius: 24px;
  padding: $unit / 6;
  background-color: $color_yellow-tan;
  color: $color_black-two;
  font-weight: 500;
  line-height: 1.2;
  &_type_active {
    background-color: $color_success;
    color: white;
  }
  &_type_pending {
    background-color: $color_selected;
    color: $color_grey;
  }
}

.CutText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Rotated {
  transition: transform $duration_shorter $easing_in-out;
  &_active {
    transform: rotate(180deg);
  }
}

.FlexCenter {
  display: flex;
  align-items: center;
  &_indent {
    margin-right: $unit;
  }
  &_fullWidth {
    width: 100%;
  }
}

.FadeIn {
  animation: fadeIn $duration_medium $easing_in-out both;
  &_fast {
    animation-duration: $duration_short;
  }
}

// one-line

.Relative {
  position: relative;
}

.ColorGrey {
  color: $color_grey;
}

.TextMedium {
  font-size: 16px;
}

.TextLight {
  font-weight: 300;
}

.TextSemiBold {
  font-weight: 500;
}

.FullWidth {
  width: 100%;
}
