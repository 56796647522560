@import 'styles/variables';

.Confirmation-Text {
  color: $color_grey;
  font-size: 18px;
  margin-bottom: $unit * 1.75;
}

.Confirmation-Actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Confirmation-Confirm {
  margin-left: $unit / 2;
  text-transform: uppercase;
}
