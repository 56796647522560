@import 'styles/variables';
@import 'styles/mixins';

.Table {
  position: relative;
}

.Table-Cell {
  transition: background-color $duration_short $easing_out;
  &_filtered {
    background-color: $color_filtered;
  }
}

.Table-Filter {
  text-transform: capitalize;
  color: $color_camel;
  .Table-Cell_filtered > & {
    font-weight: bold;
  }
}

.Table-FilterIcon {
  width: 16px;
  height: 16px;
  margin-left: $unit / 8;
}

.Table-FilterItem {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0 $unit;
}

.Table-RadioIcon {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: solid 1px $color_border;
  transition: border-color $duration_shorter $easing_out;
  &::before {
    @include pseudo;
    left: 6px;
    top: 6px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $color_black-two;
    opacity: 0;
    transform: scale(0.7);
    transition: $duration_shorter $easing_out;
    transition-property: opacity, transform;
  }
  &_checked::before {
    opacity: 1;
    transform: scale(1);
    .RadioField_disabled & {
      opacity: 0.5;
    }
  }
}

.Table-Footer {
  height: $table-footer-height;
  padding: $unit / 1.5 $unit / 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
