.MuiDrawer {
  &-paperAnchorDockedLeft {
    border: 0;
  }
  &-paper {
    background-color: $color_white;
  }
}

.MuiListItemIcon {
  &-root {
    min-width: auto;
    margin-right: $unit / 2;
    color: black;
  }
}

.MuiButton {
  &-root {
    min-width: 160px;
    min-height: 48px;
  }
}

.MuiLink {
  &-root {
    display: inline-flex;
    align-items: center;
    color: $color_dark-sky-blue;
    font-weight: 500;
    padding: $unit / 4 $unit / 3;
    white-space: nowrap;
    & > svg {
      flex-shrink: 0;
    }
  }
}

.MuiTableCell {
  &-head {
    color: $color_camel;
    white-space: nowrap;
  }
  &-root {
    padding: $unit / 1.5 $unit / 3;
  }
}

.MuiAvatar {
  &-root {
    width: 60px;
    height: 60px;
    margin-right: $unit / 1.5;
  }
}

.MuiFormControl {
  &-marginDense {
    margin-top: $unit / 2;
    margin-bottom: $unit / 4;
  }
}

.MuiOutlinedInput {
  &-input {
    padding: $unit / 1.625 $unit / 1.5;
  }
  &-multiline.MuiOutlinedInput-marginDense {
    padding: 0;
  }
}

.MuiInputLabel {
  &-outlined {
    &.MuiInputLabel-marginDense {
      transform: translate(14px, 17px) scale(1);
    }
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}

.MuiInputAdornment {
  &-root {
    font-size: 14px;
  }
}

.MuiDialog {
  &-paper {
    border-radius: 24px;
    padding: $unit * 2;
  }
  &-paperWidthSm {
    width: 496px;
  }
}

.MuiSelect {
  &-select:focus {
    border-radius: 8px;
  }
}

.MuiPagination {
  &-root {
    margin-left: $unit;
  }
}

.MuiListSubheader {
  &-root {
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    &::before,
    &::after {
      @include pseudo(relative);
      height: 1px;
      background-color: $color_border;
      width: 100%;
    }
    &::before {
      margin-right: $unit / 2;
    }
    &::after {
      margin-left: $unit / 2;
    }
  }
}

.MuiFormControlLabel {
  &-root {
    margin-left: -9px;
  }
}

.MuiInputBase {
  &-root {
    background-color: white;
  }
}

.MuiSnackbar {
  &-root {
    left: auto;
  }
}

.MuiIconButton {
  &-label {
    .Mui-disabled > & {
      opacity: 0.38;
    }
  }
}

.MuiMenuItem {
  &-root {
    text-transform: capitalize;
  }
}
