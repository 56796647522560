@import 'styles/variables';

.Answer {
  &_predefined {
    font-size: 16px;
    margin-top: $unit / 2;
    padding-left: $unit / 1.5;
    padding-right: $unit / 1.5;
  }
}

.Answer-PredefinedTitle {
  margin-bottom: $unit / 2;
}

.Answer-PredefinedItem {
  font-weight: 300;
  &:not(:last-child) {
    margin-bottom: $unit / 4;
  }
}

.Answer-Plug {
  color: $color_border;
}
