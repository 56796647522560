@import 'styles/variables';

.SectionPanel {
  display: flex;
  justify-content: space-between;
  margin-bottom: $unit * 1.5;
}

.SectionPanel-Action {
  text-align: right;
  width: 100%;
  position: relative;
  top: -6px;
  margin-left: $unit * 1.5;
}
