@import 'styles/variables';
@import 'styles/mixins';

.CompanyForm-Section {
  &:not(:last-child) {
    margin-bottom: $unit * 2;
  }
}

.CompanyForm-Title {
  display: flex;
  align-items: flex-end;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  color: $color_grey;
  margin-bottom: $unit / 1.5;
  &::after {
    @include pseudo(relative);
    margin-left: $unit / 2;
    width: 100%;
    height: 1px;
    bottom: 6px;
    background-color: $color_border;
  }
}

.CompanyForm-Reset {
  display: flex;
  align-items: center;
}

.CompanyForm-Fields {
  position: relative;
  width: 100%;
  padding-right: $unit * 1.5;
  &::before,
  &::after {
    @include pseudo;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 60px;
  }
  &::before {
    right: 8px;
    width: 26px;
    height: 60px;
    border-radius: 8px;
    border: 1px solid $color_border;
  }
  &::after {
    right: 22px;
    width: 13px;
    height: 60px;
    background-color: white;
  }
}
