@import 'styles/variables';

.ExpansionBlock {
  list-style: none;
  &:not(:last-child) {
    margin-bottom: $unit / 2;
  }
}

.ExpansionBlock-Content {
  padding: 0 $unit $unit $unit;
}
